const Info = ({ isOpen, onToggle }) => {
    return (
        <div className={`info${  isOpen ? ' is-open' : ''}`}>
            <div className="info-header">
                <img src="/assets/logo-brandweer-white-v2.svg" alt="Brandweer" className="info-header-logo" />

                <button className="info-header-toggle" onClick={onToggle}>
                    { isOpen ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-x">
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-info">
                            <circle cx="12" cy="12" r="10"></circle>
                            <line x1="12" y1="16" x2="12" y2="12"></line>
                            <line x1="12" y1="8" x2="12.01" y2="8"></line>
                        </svg>
                    ) }
                </button>

            </div>
            <div className="info-body text-container">
                <h2>Privacystatement</h2>
                <p>Het benjijtelaat spel is ontwikkeld door de Veiligheidsregio Noord- en Oost- Gelderland (VNOG). De persoonsgegevens die in het kader van de winactie door de deelnemer worden verstrekt, worden door VNOG alleen gebruikt voor de winactie en het versturen van een gepersonaliseerde e-mail. De gegevens worden niet verstrekt aan derden, niet voor andere doelen gebruikt en worden uiterlijk 3 maanden na afloop van de winactie verwijderd.  Voor het gehele privacyreglement van VNOG verwijzen we naar onze website www.vnog.nl</p>
                <h2>Winactie voorwaarden</h2>
                <p>Het benjijtelaat spel heeft een winactie. Op deze winactie zijn de volgende voorwaarden van toepassing:</p>
                <ol>
                    <li>De winactie wordt uitgeschreven door Veiligheidsregio Noord- en Oost-Gelderland. Op deze winactie zijn actievoorwaarden van toepassing.</li>
                    <li>Deelname is gratis. Deelname vindt plaats wanneer je het spel hebt afgerond en je een e-mail adres hebt achtergelaten.</li>
                    <li>Iedere deelnemer kan maximaal één keer deelnemen aan de winactie. Dubbele e-mail adressen worden niet meegenomen in het trekken van de winnaars.</li>
                    <li>Om deel te mogen nemen, moet een deelnemer een minimumleeftijd hebben van 18 jaar. Jongere deelnemers zijn uitgesloten van deelname.</li>
                    <li>De winactie loopt gedurende de periode dat de spelcontainer in de desbetreffende gemeente staat.</li>
                    <li>De trekking van de winnaar(s) geschiedt willekeurig en op onpartijdige wijze.</li>
                    <li>Uiterlijk vijf werkdagen nadat de actie is gesloten, worden winnaars persoonlijk geïnformeerd via het e-mailadres waarmee aan de actie is deelgenomen.</li>
                    <li>Over uitslagen wordt niet gecorrespondeerd.</li>
                    <li>De prijs is een rookmelder ter waarde van 25 euro. Prijzen zijn niet inwisselbaar voor geld of andere goederen.</li>
                    <li>De persoonsgegevens die in het kader van de winactie worden verkregen, worden gebruikt door Veiligheidsregio Noord- en Oost-Gelderland voor de winactie en worden niet verstrekt aan derden.</li>
                    <li>In gevallen waarin deze voorwaarden niet voorzien, zal een besluit worden genomen door Veiligheidsregio Noord- en Oost-Gelderland</li>
                    <li>Veiligheidsregio Noord- en Oost-Gelderland handelt met haar acties in overeenstemming met de Gedragscode Promotionele Kansspelen van 1 januari 2014.</li>
                    <li>Op deze voorwaarden is het Nederlands recht van toepassing.</li>
                    <li>Deelnemers die niet voldoen aan bovengenoemde voorwaarden kunnen worden uitgesloten van deelname.</li>
                    <li>Veiligheidsregio Noord- en Oost- Gelderland kan, naar eigen inzicht en zonder voorafgaande kennisgeving, deze actievoorwaarden gedurende de actielooptijd wijzigen of aanpassen, dan wel zonder opgave van reden de actie wijzigen of aanpassen tenzij dit tot nadeel van de deelnemer leidt.</li>
                    <li>Voor vragen en/of klachten kun je contact opnemen met Veiligheidsregio Noord- en Oost- Gelderland per mail aan campagnes@vnog.nl.</li>
                </ol>
            </div>
        </div>
    );
}

export default Info;