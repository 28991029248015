import { h } from 'preact';
import {Router} from 'preact-router';
import {useEffect, useState} from 'preact/hooks';

// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
import Nickname from '../routes/nickname';
import Game1Start from '../routes/game-1-start';
import Game1Play from '../routes/game-1-play';
import Game1End from '../routes/game-1-end';
import Game2Start from '../routes/game-2-start';
import Game2Play from '../routes/game-2-play';
import Game2End from '../routes/game-2-end';
import Game3Start from '../routes/game-3-start';
import Game3Play from '../routes/game-3-play';
import Game3End from '../routes/game-3-end';
import Article from '../routes/article';
import Email from '../routes/email';
import Outro from '../routes/outro';

import Desktop from './desktop';
import Info from './info';

const App = () => {
    const [showInfo, setShowInfo] = useState(false);
    const [appHeight, setAppHeight] = useState(0);

    useEffect(() => {
        function handleResize() {
            setAppHeight(window.innerHeight);
        }

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="app" style={`height:${appHeight}px`}>
            <main className="main">
                <Router>
                    <Home path='/' />
                    <Nickname path='/nickname' />
                    <Game1Start path='/game/1/start' />
                    <Game1Play path='/game/1/play' />
                    <Game1End path='/game/1/end/:status' />
                    <Game2Start path='/game/2/start' />
                    <Game2Play path='/game/2/play' />
                    <Game2End path='/game/2/end' />
                    <Game3Start path='/game/3/start' />
                    <Game3Play path='/game/3/play' />
                    <Game3End path='/game/3/end/:status' />
                    <Article path='/article' />
                    <Email path='/email' />
                    <Outro path='/outro' />
                </Router>
            </main>
            <Info isOpen={showInfo} onToggle={() => setShowInfo(showInfo => !showInfo)} />
            <Desktop />
        </div>
    );
}

export default App;
